import { aggregateObjectKeys } from "../../../utils/array";
import { capitalizeFirstLetter } from "../../../utils/string";
import { divide } from "../../../utils/numbers";

export const onTimeDeliveryData = (storeOntimeDelivery, timeframe) => {
  const dataDlv = storeOntimeDelivery.map(data => {
    const { onTimeNewNum, onTimeNewDen, missDeliveryCountNum, missDeliveryCountDen } = extractData(data, timeframe);

    return [
      { stringValue: capitalizeFirstLetter(data.TRUCK_TYPE) },
      { pctValue: divide(onTimeNewNum, onTimeNewDen) },
      { numValue: onTimeNewDen, orderBy: onTimeNewDen },
      { numValue: missDeliveryCountNum, orderBy: missDeliveryCountNum },
      { pctValue: divide(missDeliveryCountNum, missDeliveryCountDen) },
    ]
  })

  const storeOnTimeDeliveryAggregation = aggregateObjectKeys(storeOntimeDelivery)
  const {
    onTimeNewNum,
    onTimeNewDen,
    missDeliveryCountNum,
    missDeliveryCountDen,
  } = extractData(storeOnTimeDeliveryAggregation, timeframe);

  return {
    headers: [
      { name: 'Truck Type' },
      { name: 'Pick on Time %' },
      { name: 'Orders Fulfilled' },
      { name: 'Delivery Misses' },
      { name: 'Delivery Misses %' },
    ],
    data: dataDlv,
    footer: [[
      { stringValue: 'Total Orders' },
      { pctValue: onTimeNewNum / onTimeNewDen },
      { numValue: onTimeNewDen, orderBy: onTimeNewDen },
      { numValue: missDeliveryCountNum, orderBy: missDeliveryCountNum },
      { pctValue: missDeliveryCountNum / missDeliveryCountDen },
    ]]
  }
};

const extractData = (data, timeframe) => {
  return {
    onTimeNewNum: data[timeframe + '_ON_TIME_NEW_NUM'] || 0,
    onTimeNewDen: data[timeframe + '_ON_TIME_NEW_DEN'] || 0,
    missDeliveryCountNum: data[timeframe + '_MISS_DELIVERY_COUNT_NUM'] || 0,
    missDeliveryCountDen: data[timeframe + '_MISS_DELIVERY_COUNT_DEN'] || 0,
  }
};
