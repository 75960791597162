export const LEVEL_KEYS = {
  store: 'stores',
  district: 'districts',
  region: 'regions',
  division: 'divisions',
};

export const getLevelData = (level, levelNbr) => {
  if (level === 'core') return ['division', '0689'];

  return [level, levelNbr];
}
