import { fetchData } from "../modules/admin/redis/utils/fetch";
import { LEVEL_KEYS } from "./featureFlags";

export const fetchFeatureFlags = async (fpToggle, level, levelNbr) => {
  const levelKey = LEVEL_KEYS[level];
  const url = `${process.env.REACT_APP_BACKEND}/api/${fpToggle}/FeatureFlags/${levelKey}/${levelNbr}`;
  const response = await fetchData(url, 'GET');

  return response;
}
