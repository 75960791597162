import { useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimePickupAssoc } from './OnTimePickupAssoc';
import { DataView } from '../../../../../../components/Common/DataView';
import { onTimePickUpBuilder } from '../../../../utils/onTimePickUpBuilder';
import { divide } from '../../../../../../utils/numbers';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';

export const OnTimePickup = () => {
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: storeOnTimePickUp = [] }, { data: curbsidePickUp = [] }]
  } = useEnhancedQueries(['queryOnTimePickUp', 'queryPickUpCurbside'], fpToggle)

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const totalRow = storeOnTimePickUp.filter(row => row.CHANNEL === 'TOTAL');
  const bodyRows = storeOnTimePickUp.filter(row => row.CHANNEL !== 'TOTAL');
  const pickupMethodsDataObj = {
    headers: [
      { name: 'Method' },
      { name: 'Pick On Time %' },
      { name: 'Order Fulfilled TY' },
      { name: '# of Orders Missed SLA' },
      { name: 'Avg Min to Pick' },
      { name: 'DS Compliance %' }
    ],
    ...onTimePickUpBuilder({ bodyRows, totalRow, timeframe })
  };

  const curbsideDataObj = {
    headers: [
      { name: 'Method' },
      { name: 'Avg Customer Wait Time Mins' },
      { name: '# of Orders Missed SLA' },
      { name: '% of Orders Missed SLA' }
    ],
    data: curbsidePickUp.map(data => [
      { stringValue: 'Curbside' },
      {
        numValue: +data[timeframe + '_AVG_WAIT_TIME'],
        decimals: 1,
        orderBy: +data[timeframe + '_AVG_WAIT_TIME']
      },
      { numValue: data[timeframe + '_MISS_SLA_NUM'], orderBy: +data[timeframe + '_MISS_SLA_NUM'] },
      { pctValue: divide(data[timeframe + '_MISS_SLA_NUM'], data[timeframe + '_ORDER_NUMBER']) }
    ])
  };

  return (
    <DataView className='my-4'>
      <DataView.Title as='h3'>Pickup Details by Method</DataView.Title>
      <DataView.DataTable data={pickupMethodsDataObj} />
      <DataView.Title as='h3'>Pickup Details for Curbside</DataView.Title>
      <DataView.DataTable data={curbsideDataObj} />
      <DataView.Title as='h3'>More Details</DataView.Title>
      <OnTimePickupAssoc />
    </DataView>
  );
};
