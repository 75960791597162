import { createContext } from 'react';
import { fetchFeatureFlags } from '../utils/fetch';
import { getLevelData } from '../utils/featureFlags';
import { useLevelContext } from '../hooks/useLevelContext';
import { useQuery } from 'react-query';
import { useUserContext } from '../hooks/useUserContext';

export const FeatureFlagsContext = createContext({});

export const FeatureFlagsProvider = ({ children }) => {
  const { level, levelNbr } = useLevelContext();
  const { fpToggle, isLoggedIn } = useUserContext();
  const [levelName, levelNumber] = getLevelData(level, levelNbr);
  const {
    data: features = [],
    isError,
    isLoading
  } = useQuery(`featureFlags/${levelName}/${levelNumber}`, () => fetchFeatureFlags(fpToggle, levelName, levelNumber), {
    enabled: isLoggedIn && !!levelName && !!levelNumber
  });

  const value = { features, isError, isLoading };

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
